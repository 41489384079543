<template>
  <v-container fluid>
    <status :id="id" :e="e" :r="r" :df="df" />

    <v-card v-for="t in teams" :key="t.team._id">
      <v-card-title><h2>{{ t.team.name }}</h2></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">Penalty aktuell: {{ pen(t.results) | float1_0 }}</v-col>
          <v-col cols="6"><punktewaehler v-model="penalty[t.team._id]" label="Penalty neu"/></v-col>
          <v-col cols="6"><v-btn @click="save(t.team._id)">Penalty Speichern</v-btn></v-col>
        </v-row>
      </v-card-text>

      {{ mannschaftgesamtergebnis(e, r, t.team._id) }}
    </v-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'

export default {
  name: 'abschliessen',

  components: {
    Status: () => import('./allgemein/status'),
    Punktewaehler: () => import('./ergebnisse/punktewaehler')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    penalty: []
  }),

  computed: {
    teams () {
      return this.e?.teams || []
    }
  },

  methods: {
    result (results) {
      return results?.find(r => !r._discipline)
    },
    pen (results) {
      return this.result(results)?.penalty || 0
    },
    async save (_team) {
      const team = this.teams.find(t => t.team._id === _team)
      const result = this.result(team.results)
      const penalty = this.penalty[_team] || 0

      if (result) {
        await this.mutate({
          mutation: gql`
              mutation($id: UUID!, $penalty: Float!, $final: Float) {
                StbW2023WkTeamResultUpdate(id: $id, penalty: $penalty, final: $final) { _id }
              }
            `,
          variables: {
            id: result._id,
            penalty,
            final: result.final - penalty
          }
        })
      } else {
        await this.mutate({
          mutation: gql`
              mutation($event: UUID!, $team: UUID!, $penalty: Float!) {
                StbW2023WkTeamResultAdd(event: $event, team: $team, penalty: $penalty) { _id }
              }
            `,
          variables: {
            event: this.id,
            team: _team,
            penalty: penalty || 0
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
